import axios from "@/api/axios"; // 引用axios


export const meetings = (params) => {
    return axios({
        url: "/api/yealink/users/meetings",
        method: "get",
        params: params,
    });
};

export const meetingsDetail = (params) => {
    return axios({
        url: "/api/yealink/users/meeting/detail",
        method: "get",
        params: params,
    });
};