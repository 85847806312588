<template>
  <div>
    <basicTable
        :tableData="tableData"
        :isPage = "false"
        :attrList="attrList">
    </basicTable>

    <el-dialog
        append-to-body
        v-model="dialogFormVisible"
        title="详情"
        :close-on-click-modal="false"
    >
      <el-form label-position="right" :model="form" size="small" label-width="150px" ref="dialogForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="是否为周期会议">
              <span>{{ form.isRecurrence ? '是' : '否' }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议主题">
              <span>{{ form.subject }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议开始时间">
              <span>{{ formatDayTime(form.startDateTime) }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议结束时间">
              <span>{{ formatDayTime(form.endDateTime )}}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="持续时长(小时)">
              <span>{{ form.durationHour }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="持续时长(分钟)">
              <span>{{ form.durationMinute }}</span>
            </el-form-item>
          </el-col>






          <el-col :span="12">
            <el-form-item label="循环类型">
              <span>{{ getrecurrenceType(form.recurrenceType )}}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="循环间隔">
              <span>{{ form.recurrenceInterval }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="每周的周几">
              <span>{{ form.dayOfWeek }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="每月的第几天">
              <span>{{ form.dayOfMonth }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="循环开始日期">
              <span>{{ formatDayTime(form.rangeStartDate) }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="循环结束日期">
              <span>{{ formatDayTime(form.rangeEndDate) }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议号码">
              <span>{{ form.meetingNumber }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="会议密码">
              <span>{{ form.meetingPassword }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="参会成员">
              <div v-for="(item ,index) in form.participants" :key="index">{{ item.name }}</div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注">
              <span>{{ form.remark }}</span>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="外网入会链接">
              <el-button @click="wwJoin(form.joinUrl)">加入</el-button>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="内网入会链接">
              <el-button @click="nwJoin(form.intranetJoinUrl)">加入</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="meetingVisible"
        title="加入会议"
        :close-on-click-modal="false"
    >
      <iframe style="width: 100%;height: 500px" :src="meetingUrl"></iframe>
    </el-dialog>

  </div>
</template>


<script>
import { meetings, meetingsDetail } from "@/api/videomeeting/personalmeeting";
export default {
  data(){
    return{
      meetingUrl: "",
      meetingVisible:false,
      form:{},
      dialogFormVisible:false,
      attrList:[
        {
          prop: "subject",
          label: "会议主题",
          render: (row) => <div>{row.subject}</div>
        },
        {
          prop: "organizerName",
          label: "组织者名称",
          render: (row) => <div>{row.organizerName}</div>
        },
        {
          prop: "startDateTime",
          label: "会议开始时间",
          render: (row) => <div>{this.formatDayTime(row.startDateTime)}</div>
        },
        {
          prop: "endDateTime",
          label: "会议结束时间",
          render: (row) => <div>{this.formatDayTime(row.endDateTime)}</div>
        },
        {
          prop: "meetingNumber",
          label: "会议号",
          render: (row) => <div>{row.meetingNumber}</div>
        },
        {
          prop: "meetingPassword",
          label: "会议密码",
          render: (row) => <div>{row.meetingPassword}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="View" onClick={() => this.onView(row)}>详情</el-button>
        },
      ],
      tableData:[],
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
    }
  },

  created() {
    this.onLoad();
  },

  computed:{
    formatDayTime(){
      return function (val) {
        if(val) {
          let date = new Date(val)
          let Y = date.getFullYear();
          let M = date.getMonth() + 1;
          let D = date.getDate();
          var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours())
          var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes())
          var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());

          if(M < 10) {
            M = '0' + M;
          }
          if(D < 10) {
            D = '0' + D;
          }

          return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
        } else {
          return '';
        }
      }
    },

    getrecurrenceType(){
      let op = new Map([
        [0, '按天循环'],
        [1, '按周循环'],
        [2, '按月循环'],
      ])
      return function (value){
        return op.get(value)
      }
    }
  },

  methods:{
    wwJoin(url){
      window.open(url, '_blank')
    },
    nwJoin(url){
      // meetingVisible=true;meetingUrl=form.intranetJoinUrl
      window.open(url, '_blank')
    },
    onView(row){
      meetingsDetail({
        meetingPlanId: row.id,
        sequence: row.sequence
      }).then(res=>{
        let data = JSON.parse(res.data.data);
        this.form = data;
        this.dialogFormVisible = true;
      })
    },

    getDate(){
      var ss = 24 * 60 * 60 * 1000; //一天的毫秒数86400
      var timestamp = new Date().getTime(); //获取当前时间戳
      var date1 = new Date(ss * 30 + timestamp).getTime();
      return {
        timestamp,
        date1
      }
    },

    onLoad(){
      let { timestamp, date1 } = this.getDate()
      meetings({
        from: timestamp,
        to: date1
      }).then(res=>{
        let data = JSON.parse(res.data.data);
        this.tableData= data.conferencePlans;
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>