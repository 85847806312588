import axios from "@/api/axios"; // 引用axios


export const meetings = (params) => {
    return axios({
        url: "/api/yealink/meetings",
        method: "get",
        params: params,
    });
};


export const monitorLinks = (params) => {
    return axios({
        url: "/api/yealink/meetings/monitor-links",
        method: "get",
        params: params,
    });
};
