<template>
  <div>
    <basicTable
        :tableData="tableData"
        :isPage = "false"
        :attrList="attrList">
    </basicTable>

    <el-dialog
        append-to-body
        v-model="meetingVisible"
        title="会议监控"
        :close-on-click-modal="false"
    >
      <iframe style="width: 100%;height: 500px" :src="meetingUrl"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { meetings, monitorLinks } from "@/api/videomeeting/enterprisemeeting";
export default {
  data(){
    return{
      meetingUrl:"",
      meetingVisible:false,
      tableData:[],
      attrList:[
        {
          prop: "subject",
          label: "会议主题",
          render: (row) => <div>{row.subject}</div>
        },
        {
          prop: "organizerName",
          label: "组织者名称",
          render: (row) => <div>{row.organizerName}</div>
        },
        {
          prop: "startDateTime",
          label: "会议开始时间",
          render: (row) => <div>{this.formatDayTime(row.startDateTime)}</div>
        },
        {
          prop: "endDateTime",
          label: "会议结束时间",
          render: (row) => <div>{this.formatDayTime(row.endDateTime)}</div>
        },
        {
          prop: "meetingNumber",
          label: "会议号",
          render: (row) => <div>{row.meetingNumber}</div>
        },
        {
          prop: "meetingPassword",
          label: "会议密码",
          render: (row) => <div>{row.meetingPassword}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="View" onClick={() => this.onJoin(row)}>进入会议监控</el-button>
        },
      ]
    }
  },
  mounted() {

  },

  computed:{
    formatDayTime(){
      return function (val) {
        if(val) {
          let date = new Date(val)
          let Y = date.getFullYear();
          let M = date.getMonth() + 1;
          let D = date.getDate();
          var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours())
          var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes())
          var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());

          if(M < 10) {
            M = '0' + M;
          }
          if(D < 10) {
            D = '0' + D;
          }

          return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
        } else {
          return '';
        }
      }
    },
  },

  methods:{
    getDate(){
      var ss = 24 * 60 * 60 * 1000; //一天的毫秒数86400
      var timestamp = new Date().getTime(); //获取当前时间戳
      var date1 = new Date(ss * 30 + timestamp).getTime();
      return {
        timestamp,
        date1
      }
    },

    onJoin(row){
      monitorLinks({
        meetingNumber:row.meetingNumber
      }).then(res=>{
        let url = JSON.parse(res.data.data);
        // this.meetingVisible = true;
        window.open(url.monitorLink.extranetWeb, '_blank');
        // this.meetingUrl = url.monitorLink.extranetWeb;
      })
    },
    onLoad(){
      let { timestamp, date1 } = this.getDate()
      meetings({
        from: timestamp,
        to: date1
      }).then(res=>{
        let data = JSON.parse(res.data.data);
        this.tableData= data.conferencePlans;
      })
    }
  },
  created() {
    this.onLoad()
  }
}
</script>

<style scoped lang="scss">

</style>